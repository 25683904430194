import React, { useEffect } from 'react';
import Layout from '../../components/commons/Layout';
import styled from 'styled-components';
import theme from '../../constants/theme';
import Markdown from '../../components/commons/Markdown';
import SectionTitleAndDescription from '../../components/sections/SectionTitleAndDescription';
import Container from '../../components/layout/Container';
import SectionHero from '../../components/sections/SectionHero';
import ResponsiveText from '../../components/typography/ResponsiveText';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

export default function JobDetail({ pageContext: { node, seoRules } }) {
  const {
    title,
    tipoContratto,
    tags,
    reparto,
    partenza,
    esperienza,
    content,
    introduction,
    hubspotForm,
  } = node;
  let tagsArray = tags && tags.split(',');

  const JobDetailsWrapper = styled.div`
    .detail-header {
      display: flex;
      flex-flow: column wrap;

      h2 {
        font-size: 30px;
        text-transform: uppercase;
      }

      h1 {
        margin-bottom: 20px;
      }

      .detail-image {
        flex: 1;
      }
    }

    .info {
      background-color: ${theme.colorYellow};
      padding: 50px 0;
      margin-top: 30px;
      margin-bottom: 100px;

      h3 {
        font-size: 30px;
        letter-spacing: -2.3px;
        margin-bottom: 10px;
      }

      p {
        font-size: 27px;
      }
    }

    .container {
      flex-wrap: wrap;
    }

    ul.tags {
      margin-bottom: 30px;
      flex: 1 0 100%;
      overflow: hidden !important;

      li {
        display: inline-block;
        font-size: 21px;
        background-color: ${theme.colorYellow};
        padding: 5px;
        text-transform: uppercase;
        margin-right: 25px;
      }
    }

    h3 {
      font-size: 30px;
      margin-bottom: 30px;
    }

    .markdown {
      h2 {
        font-size: calc(77px + (98 - 77) * ((100vw - 320px) / (1600 - 320)));
        line-height: calc(72px + (92 - 72) * ((100vw - 320px) / (1600 - 320)));
        letter-spacing: -5px;
        margin-bottom: 30px;
        margin-top: 100px;
      }

      h3 {
        font-size: 30px;
        margin-top: 30px;
      }

      ul {
        list-style-type: disc;
        margin-left: 20px;
      }

      font-size: 21px;
    }

    .job-form-container {
      background-color: ${theme.colorBlack};
      color: ${theme.colorWhite};
      height: 100vh;
      max-height: 1080px;

      .grid {
        height: 100%;
      }

      .job-heading {
        display: flex;
        align-items: center;
        justify-content: left;

        h2.mobile {
          display: none;
        }
      }

      .form-wrapper {
        display: flex;
        align-items: center;
      }
    }

    @media (max-width: 1024px) {
      .markdown {
        width: 100%;
      }

      ul.tags {
        white-space: nowrap;
        overflow: scroll;
      }

      .job-form-container {
        height: 100vh;
        max-height: 800px;

        .grid {
          height: auto;
        }

        .job-heading {
          align-items: flex-start;
          justify-content: flex-start;
          padding-top: 20px;

          h2.desktop {
            display: none;
          }

          h2.mobile {
            display: block;
          }
        }
      }
    }

    @media (max-width: 480px) {
      .markdown {
        h2 {
          font-size: 70px;
          line-height: 65px;
          letter-spacing: -5px;
          line-break: anywhere;
        }
      }
    }

    @media (max-width: 374px) {
      .header {
        h1 {
          font-size: 40px;
          line-height: 36px;
          letter-spacing: -2px;
        }
      }

      .job-form-container {
        height: auto;
        min-height: 100vh;
        max-height: unset;
      }

      .markdown {
        h2 {
          font-size: 28px;
          line-height: 24px;
          letter-spacing: 0;
        }

        h3 {
          font-size: 18px;
        }
      }
    }
  `;

  useEffect(function () {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '14542219',
          formId: hubspotForm,
          target: '#form-hs',
        });
      }
    });
  }, []);

  return (
    <Layout seo={seoRules && seoRules[0] && seoRules[0].seo}>
      <JobDetailsWrapper>
        {/* <div className="detail-header container mx-auto" data-scroll-section>
          <h1 className="responsive-text-e">{title}</h1>
        </div> */}
        <SectionHero
          title={title}
          titleResponsiveType="bigger"
          alignTitleBottom
          noMinHeight
        />
        {introduction && (
          <SectionTitleAndDescription
            title={introduction.title}
            description={introduction.description}
          />
        )}
        <div className="info" data-scroll-section>
          <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto gap-y-12">
            {partenza && (
              <div>
                <h3>Partenza</h3>
                <p>{partenza}</p>
              </div>
            )}
            {tipoContratto && (
              <div>
                <h3>Tipo di Contratto</h3>
                <p>{tipoContratto}</p>
              </div>
            )}
            {esperienza && (
              <div>
                <h3>Esperienza</h3>
                <p>{esperienza}</p>
              </div>
            )}
            {reparto && (
              <div>
                <h3>Reparto</h3>
                <p>{reparto}</p>
              </div>
            )}
          </div>
        </div>
        {tagsArray && tagsArray.length > 0 && (
          <div data-scroll-section style={{ marginBottom: '20px' }}>
            <Container>
              <h3>Skills Overview</h3>
              <ul className="tags">
                {tagsArray.map((tag, i) => (
                  <li key={i}>{tag}</li>
                ))}
              </ul>
            </Container>
          </div>
        )}
        <div
          data-scroll-section
          data-scroll
          data-scroll-id={
            'titleAndDescriptionSection_' + Math.floor(Math.random() * 50) + 1
          }
        >
          <Container>
            <Markdown content={content} />
          </Container>
        </div>
        <Helmet>
          <script
            charset="utf-8"
            type="text/javascript"
            src="//js.hsforms.net/forms/embed/v2.js"
          />
        </Helmet>
        <div
          className="job-form-container"
          data-scroll
          data-scroll-id="joinUsSection"
          data-scroll-section
          style={{ marginTop: '70px' }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto">
            <div className="job-heading">
              <ResponsiveText type="big">
                <h2 className="desktop">
                  Join
                  <br /> Us
                </h2>
                <h2 className="mobile">Join Us</h2>
              </ResponsiveText>
            </div>
            <div className="form-wrapper" id="form-hs"></div>
          </div>
        </div>
      </JobDetailsWrapper>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
