import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`

  @media (max-width: 1024px) {
    max-width: 100vw;
    &.h-full {
      height: auto;
    }
  }
`

export default function Container2({ children }) {
  return (
    <StyledContainer className="container mx-auto h-full flex">{children}</StyledContainer>
  )
}