import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Container2 from '../layout/Container2'
import theme from '../../constants/theme'
import ResponsiveText from '../typography/ResponsiveText'

const Wrapper = styled.section`
  height: auto;
  min-height: ${props => props.noMinHeight? `unset` : `calc(100vh - 96px)`};

  .container {
    flex-wrap: wrap;
  }

  .header {
    display: flex;
    width: 100%;
    min-height: 160px;

    ${props => !props.isHomePage && `
      flex-wrap: wrap;
    `}

    .title {
      flex: 1 0 100%;

      ${props => props.isHomePage && `
        flex: 0;
        max-width: 50%;
      `}
    }

    .description {
      h2 {
        font-family: ${theme.fontNeue};
        text-transform: unset;
        font-weight: 500;
      }

      flex: 1;
      align-self: flex-end;
    }
  }

  .featured-image {
    width: 100%;
    height: 70%;
    margin-left:0;
    margin-top: 120px;

    .gatsby-image-wrapper {
      /*max-height: 100%;*/
      width: 100%;
      & > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  ul.tags {
    margin-bottom: 30px;

    li {
      display: inline-block;
      font-size: 21px;
      background-color: ${theme.colorYellow};
      padding: 5px;
      text-transform: uppercase;
      margin-right: 25px;
    }
  }

  @media (max-width: 1024px) {
    height: ${props => props.featuredImage ? (props.noHeight?'':'1px'): ''};

    .header {
      flex-wrap: wrap;
      align-self: flex-start;

      .title {
        max-width: unset;
        flex: 1 0 100%;
      }

      .description {
        align-self: flex-start;
      }
    }

    .featured-image {
      margin:0;
      margin-top: 20px;
      width: 100%;
      height: 70%;

      .gatsby-image-wrapper {
        height: 100%;
      }
    }

    ul.tags {
      white-space: normal;
      li {
        margin-top: 9px;
      }
      overflow: scroll;
    }
  }
`

export default function SectionHero({ title, titleResponsiveType, description, featuredImage, noHeight, isHomePage, tags, noMinHeight = false, noScroll = false }) {
  let tagsArray = tags && tags.split(",")

  return (
    <Wrapper isHomePage={isHomePage} noHeight={noHeight} noMinHeight={noMinHeight} featuredImage={featuredImage}>
      <Container2>
        <div className="header">
          <div className="title">
            <ResponsiveText type={titleResponsiveType ? titleResponsiveType : 'extreme'}><h1>{title}</h1></ResponsiveText>
          </div>
          <div className="description">
            <ResponsiveText><h2>{description}</h2></ResponsiveText>
          </div>
          
          {tagsArray && tagsArray.length > 0 && <ul className="tags">
            {tagsArray.map((tag, i) => (
              <li key={i}>{tag}</li>
            ))}
          </ul>}
        </div>
      </Container2>
      {featuredImage && <div className="featured-image hover-img">
            <GatsbyImage image={getImage(featuredImage.imageFile)} alt="" />
          </div>}
    </Wrapper>
  )
}